import { render, staticRenderFns } from "./zl_jd_giftinfo.vue?vue&type=template&id=45ea3f18&scoped=true"
import script from "./zl_jd_giftinfo.vue?vue&type=script&lang=js"
export * from "./zl_jd_giftinfo.vue?vue&type=script&lang=js"
import style0 from "./zl_jd_giftinfo.vue?vue&type=style&index=0&id=45ea3f18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ea3f18",
  null
  
)

export default component.exports